import React from "react";
import "./style.css";
import cookies from "js-cookie";

export default function ConfirmAnimation() {
  const lang = cookies.get("i18next");

  return (
    <div
      class="icon-animated icon-animated-tick"
      tabindex="-1"
      aria-hidden="true"
      style={{ marginTop: "60px" }}
    >
      <svg
        class="circle"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
      >
        <circle cx="50" cy="50" r="50" />
      </svg>

      <div className={lang === "ar" ? "tick tick-ar" : "tick"}>
        <svg
          class="tick-leg1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 25 52"
        >
          <polygon class="" points="1,41 0,48 25,52 25,45" />
        </svg>
        <svg
          class="tick-leg2"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 25 52"
        >
          <polygon class="" points="18,45 25,47 25,0 18,0" />
        </svg>
      </div>
    </div>
  );
}

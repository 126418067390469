import { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Tabfour from "../../Screens/Tabs/Tabfour";
import Tabone from "../../Screens/Tabs/Tabone";
import Tabthree from "../../Screens/Tabs/Tabthree";
import Tabtwo from "../../Screens/Tabs/Tabtwo";
import Checkbox from "../CheckBox/Checkbox";
import "./style.css";

export function ControlledTabs() {
  const [key, setKey] = useState("home");
  const [checkOne, setCheckOne] = useState(localStorage.getItem("may_8"));
  const [checkTwo, setCheckTwo] = useState(localStorage.getItem("may_9"));
  const [checkThree, setCheckThree] = useState(localStorage.getItem("may_10"));
  const [checkFour, setCheckFour] = useState(localStorage.getItem("may_11"));
  const { t } = useTranslation();

  return (
    <Tabs
      id="controlled-tab-example"
      activeKey={key}
      onSelect={(k) => setKey(k)}
      className="d-flex justify-content-between p-0"
    >
      <Tab
        eventKey="home"
        title={
          <div className="d-flex">
            <Checkbox name="may_8" value={checkOne} />
            <p className="px-1">{t("8may")}</p>
          </div>
        }
      >
        <Tabone setCheckOne={setCheckOne} />
      </Tab>
      <Tab
        eventKey="profile"
        title={
          <div className="d-flex">
            <Checkbox name="may_9" value={checkTwo} />
            <p className="px-1">{t("9may")}</p>
          </div>
        }
      >
        <Tabtwo setCheckTwo={setCheckTwo} />
      </Tab>
      <Tab
        eventKey="contact"
        title={
          <div className="d-flex">
            <Checkbox name="may_10" value={checkThree} />
            <p className="px-1">{t("10may")}</p>
          </div>
        }
      >
        <Tabthree setCheckThree={setCheckThree} />
      </Tab>
      <Tab
        eventKey="contactt"
        title={
          <div className="d-flex">
            <Checkbox name="may_11" value={checkFour} />
            <p className="px-1">{t("11may")}</p>
          </div>
        }
      >
        <Tabfour setCheckFour={setCheckFour} />
      </Tab>
    </Tabs>
  );
}

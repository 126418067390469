import React, { useState } from "react";
import "./style.css";

export default function Checkbox({type="checkbox", value, name ,onClick}) {
  const [isChecked, setIsChecked] = useState(localStorage.getItem(name));

    if (isChecked || value) localStorage.setItem(name, "true");
    
    else localStorage.removeItem(name);

  return (
    <label className="checkbox-button">
      <input
        type={type}
        className="checkbox-button__input"
        id="choice1-1"
        name={name}
        value={value}
        onChange={() => {
          setIsChecked(!isChecked);
          onClick()
        }}     
        
      />
   {isChecked||value ? <span className="checkbox-button-green-bg"></span> : <span className="checkbox-button__control"></span>}  
    </label>
  );
}

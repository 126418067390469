import React, { useEffect, useState } from "react";
import "./style.css";

export default function DisabledCheckbox() {
  return (
    <div className="checkbox-button">
      <input
        className="checkbox-button-gray-bg"
        type="radio"
        name="flexRadioDisabled"
        id="flexRadioCheckedDisabled"
        checked
        disabled
      />
    </div>
  );
}

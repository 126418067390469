import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import Layout from "../Layout/Layout";
import confirm from "../../Assets/Group 33.svg";
import { useTranslation } from "react-i18next";
import "./style.css";
import { Formik, Form, Field } from "formik";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { WorkValidationSchema } from "../../val";
import PreviousImg from "../../Assets/Group 21.svg";
import NextImg from "../../Assets/Group 22.svg";
import cookies from "js-cookie";
import { ControlledTabs } from "../../Components/Tabs/Tabs";
import { useParams } from "react-router-dom";
import Data from "./Data";

export default function Stepthree() {
  const { t } = useTranslation();
  const history = useHistory();
  const lang = cookies.get("i18next");

  const params = useParams();

  useEffect(() => {
    localStorage.setItem("language", lang);
  }, [lang]);

  return (
    <Layout>
      <Formik
        initialValues={{
          name: localStorage.getItem("name"),
          email: localStorage.getItem("email"),
          phone: localStorage.getItem("phone"),
        }}
        validationSchema={WorkValidationSchema}
        onSubmit={async (values) => {
        }}
      >
        {({ errors, touched, handleSubmit, setFieldValue, values }) => (
          <Form className="content-text " onSubmit={handleSubmit}>
            <div className="content-text padding-control ">
              <h5 className="company-text mb-mobile fs-21 mb-4">
                {" "}
                {t("Registration_information")}{" "}
              </h5>
              <div className="form-group row w-100" style={{ height: "55px" }}>
                <div className="d-flex flex-column align-items-start ">
                  <Field
                    name="name"
                    className="form-control"
                    type="text"
                    placeholder={t("Name")}
                    onChange={(e) => {
                      setFieldValue("name", e.target.value);
                      localStorage.setItem("name", e.target.value);
                    }}
                    value={values.name}
                  />

                  {errors.name && touched.name && (
                    <span className="error">{t("required")}</span>
                  )}
                </div>
              </div>

              <div className="form-group row w-100" style={{ height: "55px" }}>
                <div className="d-flex flex-column align-items-start w-50">
                  <Field
                    name="phone"
                    type="text"
                    className="form-control"
                    placeholder={t("Phone")}
                    onChange={(e) => {
                      setFieldValue("phone", e.target.value);
                      localStorage.setItem("phone", e.target.value);
                    }}
                    value={values.phone}
                  />
                  {errors.phone && touched.phone ? (
                    <span className="error">{t("required")}</span>
                  ) : null}
                </div>

                <div className="d-flex flex-column align-items-start w-50">
                  <Field
                    name="email"
                    type="text"
                    className="form-control"
                    placeholder={t("Email")}
                    onChange={(e) => {
                      setFieldValue("email", e.target.value);
                      localStorage.setItem("email", e.target.value);
                    }}
                    value={values.email}
                  />
                  {errors.email && touched.email ? (
                    <span className="error">{t("required")}</span>
                  ) : null}
                </div>
              </div>
              <h5 className="company-text mb-mobile fs-21 mb-4">
                {t("Workshop_Date")}
              </h5>
              <div>
                <ControlledTabs />
              </div>
            </div>

            <div className="content-text mt-4">
              <div
                className={
                  lang === "ar"
                    ? " d-flex flex-row-reverse justify-content-between wrapper-step-3"
                    : "  d-flex justify-content-between wrapper-step-3-ar "
                }
              >
                <div
                  className={
                    lang === "ar"
                      ? "d-flex flex-row-reverse justify-content-center"
                      : "d-flex justify-content-center"
                  }
                  onClick={() => {
                    if (
                      Data.some(
                        (item) =>
                          localStorage.getItem(item.nameBackend) == "true"
                      )
                    )
                      history.push(
                        `/workshop/${lang} `
                      );

                      else return (<div className="error d-flex justify-content-end"> 000000 </div> )
                  }}
                >
                  {lang === "ar" ? (
                    <div className="next-icon fw-bold">
                      <img src={PreviousImg} alt="Previous" />
                    </div>
                  ) : (
                    <div className="next-icon fw-bold">
                      <img src={NextImg} alt="next" />
                    </div>
                  )}
                  <Button type="submit" variant="success">
                    {t("Next")}
                  </Button>
                </div>

                <div
                  className={
                    lang === "ar"
                      ? "d-flex flex-row-reverse justify-content-center"
                      : "d-flex justify-content-center"
                  }
                  onClick={() => {
                    history.push(`/`);
                  }}
                >
                  <Button type="submit" variant="success">
                    {t("Previous")}
                  </Button>
                  {lang === "ar" ? (
                    <div className="next-icon fw-bold">
                      <img src={NextImg} alt="next" />
                    </div>
                  ) : (
                    <div className="next-icon fw-bold">
                      <img src={PreviousImg} alt="Previous" />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Layout>
  );
}

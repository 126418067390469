import React, { useState } from "react";
import "./style.css";
import logo from "../../Assets/logo.png";
import Footer from "../../Components/Footer/Footer";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import cookies from "js-cookie";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";

const languages = [
  {
    code: "en",
    name: "English",
    country_code: "gb",
    dir: "ltr",
  },
  {
    code: "ar",
    name: "العربية",
    country_code: "sa",
    dir: "rtl",
  },
];

export default function Layout({ children }) {
  const [color, setColor] = useState(true);

  const currentLanguageCode = cookies.get("i18next") || "ar";
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    document.body.dir = currentLanguage.dir || "rtl";
    document.title = t("app_title");
  }, [currentLanguage, t]);

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className="ar-container">
      <div className="header-container">
        <p
          onClick={() => {
            i18next.changeLanguage("ar");
            setColor(true);
          }}
       
          className={color == false ? " register-text" : "red"}
        >
          تسجيل الان
        </p>
        <div
          className="logo-cursor"
          onClick={() => {
            history.push("/");
          }}
        >
          {" "}
          <img src={logo} alt="logo" className="logo" />{" "}
        </div>
        <p
          onClick={() => {
            i18next.changeLanguage("en");
            setColor(false);
          }}
          className={color === true ? " register-text" : "red"}
        >
          Register Now
        </p>
      </div>
      <h4 className="main-text text-center">{t("main_text")}</h4>
      <div className="contant-container">{children}</div>
      <Footer />
    </div>
  );
}

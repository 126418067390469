import React, { useCallback, useEffect } from "react";
import { Button } from "react-bootstrap";
import Layout from "../Layout/Layout";
import confirm from "../../Assets/Group 33.svg";
import { useTranslation } from "react-i18next";
import "./style.css";
import { Formik, Form, Field } from "formik";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { WorkValidationSchema } from "../../val";
import PreviousImg from "../../Assets/Group 21.svg";
import NextImg from "../../Assets/Group 22.svg";
import cookies from "js-cookie";

import { useParams } from "react-router-dom";
import Data from "./Data";
import Checkbox from "../../Components/CheckBox/Checkbox";

export default function WorkShop({ setData }) {
  const { t } = useTranslation();
  const history = useHistory();
  const lang = cookies.get("i18next");

  const params = useParams();

  useEffect(() => {
    localStorage.setItem("language", lang);
  }, [lang]);

  const req = async () => {
    const formData = new FormData();
    formData.append("name", localStorage.getItem("name"));
    formData.append("email", localStorage.getItem("email"));
    formData.append("phone", localStorage.getItem("phone"));
    formData.append("language", localStorage.getItem("language"));
    formData.append("may_8", localStorage.getItem("may_8"));
    formData.append("may_9", localStorage.getItem("may_9"));
    formData.append("may_10", localStorage.getItem("may_10"));
    formData.append("may_11", localStorage.getItem("may_11"));
    formData.append("course1_8", localStorage.getItem("course1_8"));
    formData.append("course2_8", localStorage.getItem("course2_8"));
    formData.append("course3_8", localStorage.getItem("course3_8"));
    formData.append("course4_8", localStorage.getItem("course4_8"));
    formData.append("course5_8", localStorage.getItem("course5_8"));
    formData.append("course6_8", localStorage.getItem("course6_8"));
    formData.append("course7_8", localStorage.getItem("course7_8"));
    formData.append("course8_8", localStorage.getItem("course8_8"));
    formData.append("course9_8", localStorage.getItem("course9_8"));
    formData.append("course10_8", localStorage.getItem("course10_8"));
    formData.append("course11_8", localStorage.getItem("course11_8"));
    formData.append("course12_8", localStorage.getItem("course12_8"));
    formData.append("course1_9", localStorage.getItem("course1_9"));
    formData.append("course2_9", localStorage.getItem("course2_9"));
    formData.append("course3_9", localStorage.getItem("course3_9"));
    formData.append("course4_9", localStorage.getItem("course4_9"));
    formData.append("course5_9", localStorage.getItem("course5_9"));
    formData.append("course6_9", localStorage.getItem("course6_9"));
    formData.append("course7_9", localStorage.getItem("course7_9"));
    formData.append("course8_9", localStorage.getItem("course8_9"));
    formData.append("course9_9", localStorage.getItem("course9_9"));
    formData.append("course10_9", localStorage.getItem("course10_9"));
    formData.append("course11_9", localStorage.getItem("course11_9"));
    formData.append("course1_10", localStorage.getItem("course1_10"));
    formData.append("course2_10", localStorage.getItem("course2_10"));
    formData.append("course3_10", localStorage.getItem("course3_10"));
    formData.append("course4_10", localStorage.getItem("course4_10"));
    formData.append("course5_10", localStorage.getItem("course5_10"));
    formData.append("course6_10", localStorage.getItem("course6_10"));
    formData.append("course7_10", localStorage.getItem("course7_10"));
    formData.append("course8_10", localStorage.getItem("course8_10"));
    formData.append("course9_10", localStorage.getItem("course9_10"));
    formData.append("course10_10", localStorage.getItem("course10_10"));
    formData.append("course11_10", localStorage.getItem("course11_10"));
    formData.append("course1_11", localStorage.getItem("course1_11"));
    formData.append("course2_11", localStorage.getItem("course2_11"));
    formData.append("course3_11", localStorage.getItem("course3_11"));
    formData.append("course4_11", localStorage.getItem("course4_11"));
    formData.append("course5_11", localStorage.getItem("course5_11"));
    formData.append("course6_11", localStorage.getItem("course6_11"));
    formData.append("course7_11", localStorage.getItem("course7_11"));
    formData.append("course8_11", localStorage.getItem("course8_11"));
    formData.append("course9_11", localStorage.getItem("course9_11"));
    formData.append("course10_11", localStorage.getItem("course10_11"));
    formData.append("course11_11", localStorage.getItem("course11_11"));
    const config = {
      headers: {
        "Content-Type":
          "multipart/form-data; boundary=<calculated when request is sent>",
      },
    };
    await axios.post(
      `https://dashboard.moe-workshops.its.ae/api/order/store`,

      formData,
      config
    );
  };

  const handleClick = useCallback((item) => {
    setData((prev) => {
      const res = prev.map((_item) => {
        if (item.key === _item.key)
          return {
            ..._item,
            check: !_item.check,
          };
        else return _item;
      });
      return res;
    });
    localStorage.setItem(item.nameBackend, "true");
  }, []);

  return (
    <Layout>
      <Formik
        initialValues={{
          name: localStorage.getItem("name"),
          email: localStorage.getItem("email"),
          phone: localStorage.getItem("phone"),
        }}
        onSubmit={async (values) => {
          await req();
          history.push("/confirm");
        }}
      >
        {({ handleSubmit }) => (
          <Form className="content-text-workshop padding-control" onSubmit={handleSubmit}>

            <h5 className="my-3">{t("workshop_confirm")}</h5>
            <div className="bg-color">
              {Data.map((item) => {
                return localStorage.getItem(item.nameBackend) == "true" ? (
                  <div className=" d-flex justify-content-between mb-3">
                    <div className="w-50 d-flex">
                      <div>
                        <Checkbox
                          onClick={() => handleClick(item)}
                          value={item.check}
                          name={item.nameBackend}
                        />
                      </div>
                      <div className="mx-1"> {t(item.nameBackend)} </div>
                    </div>
                    <div className="w-25">
                      <p>{t(item.engName)}</p>
                    </div>
                    <div className="d-flex w-18">
                      <p>
                        {item.time} ({t(item.date)}) 
                      </p>
                    </div>
                  </div>
                ) : null;
              })}
            </div>

            <div className="content-text-workshop-btn mt-4">
              <div
                className={
                  lang === "ar"
                    ? " d-flex flex-row-reverse justify-content-between wrapper-workshop"
                    : "  d-flex justify-content-between wrapper-workshop-ar "
                }
              >
                <div
                  className={
                    lang === "ar"
                      ? "d-flex flex-row-reverse justify-content-center"
                      : "d-flex justify-content-center"
                  }
                  // onClick={() => {
                  //   history.push(`/confirm-workship/${lang}`);
                  // }}
                >
                  {lang === "ar" ? (
                    <div className="next-icon fw-bold">
                      <img src={confirm} alt="confirm" />
                    </div>
                  ) : (
                    <div className="next-icon fw-bold">
                      <img src={confirm} alt="confirm" />
                    </div>
                  )}
                  <Button type="submit" variant="success">
                    {t("Confirm")}
                  </Button>
                </div>

                <div
                  className={
                    lang === "ar"
                      ? "d-flex flex-row-reverse justify-content-center"
                      : "d-flex justify-content-center"
                  }
                  onClick={() => {
                    history.push(`/register/${lang}`);
                  }}
                >
                  <Button type="submit" variant="success">
                    {t("Previous")}
                  </Button>
                  {lang === "ar" ? (
                    <div className="next-icon fw-bold">
                      <img src={NextImg} alt="next" />
                    </div>
                  ) : (
                    <div className="next-icon fw-bold">
                      <img src={PreviousImg} alt="Previous" />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Layout>
  );
}

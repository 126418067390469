import { useState, useCallback, useEffect } from "react";
import Checkbox from "../../Components/CheckBox/Checkbox";
import "./style.css";
import { useTranslation } from "react-i18next";
import axios from "axios";
import Loading from "../../Components/Loading/Loading";
import DisabledCheckbox from "../../Components/CheckBoxRadio/Checkbox";

export default function Tabone({ setCheckOne }) {
  const { t } = useTranslation();

  const handleClick = useCallback((item) => {
    setData((prev) => {
      const res = prev.map((_item) => {
        if (item.key === _item.key)
          return {
            ..._item,
            check: !_item.check,
          };
        else return _item;
      });
      return res;
    });
  }, []);

  const [data, setData] = useState([
    {
      check: localStorage.getItem("course1_8"),
      nameBackend: "course1_8",
      name: t("course1_8"),
      engName: "eng1_8",
      time: "10:00 - 10:25 AM",
      key: 1,
    },
    {
      check: localStorage.getItem("course2_8"),
      nameBackend: "course2_8",
      name: t("course2_8"),
      engName: "eng2_8",
      time: "10:30 - 10:55 AM",
      key: 2,
    },
    {
      check:  localStorage.getItem("course3_8"),
      nameBackend: "course3_8",
      name: t("course3_8"),
      engName: "eng3_8",
      time: "11:00 - 11:25 AM",
      key: 3,
    },
    {
      name: t("course4_8"),
      check:  localStorage.getItem("course4_8"),
      nameBackend: "course4_8",
      engName: "eng4_8",
      time: "11:30 - 11:55 AM",
      key: 4,
    },
    {
      check:  localStorage.getItem("course7_8"),
      nameBackend: "course7_8",
      name: t("course7_8"),
      engName: "eng7_8",
      time: "5:00 - 5:25 PM",
      key: 7,
    },
    {
      check:  localStorage.getItem("course8_8"),
      nameBackend: "course8_8",
      name: t("course8_8"),
      engName: "eng8_8",
      time: "5:30 - 5:55 PM",
      key: 8,
    },
    {
      check:  localStorage.getItem("course9_8"),
      nameBackend: "course9_8",
      name: t("course9_8"),
      engName: "eng9_8",
      time: "6:00 - 6:25 PM",
      key: 9,
    },
    {
      check:  localStorage.getItem("course10_8"),
      nameBackend: "course10_8",
      name: t("course10_8"),
      engName: "eng10_8",
      time: "6:30 - 6:55 PM",
      key: 10,
    },
    {
      check:  localStorage.getItem("course11_8"),
      name: t("course11_8"),
      nameBackend: "course11_8",
      engName: "eng11_8",
      time: "7:00 - 7:25 PM",
      key: 11,
    },
    {
      check:  localStorage.getItem("course12_8"),
      name: t("course12_8"),
      nameBackend: "course12_8",
      engName: "eng12_8",
      time: "7:30 - 7:55 PM",
      key: 12,
    },
  ]);

  const [req, setReq] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (data.some((item) => item.check == true)) setCheckOne(true);
    // else setCheckOne(false);
  }, [data]);

  useEffect(async () => {
    const res = await axios.get(
      `https://dashboard.moe-workshops.its.ae/api/order`
    );
    const index = res.data;
    setReq(res.data);

    setLoading(false);
  }, []);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className="bg-color">
          {data.map((item) => {
            return (
              <div className=" d-flex justify-content-between mb-3">
                <div className="w-50 d-flex">
                  <div>
                    {req[item.nameBackend] >= 20 ? (
                      <DisabledCheckbox />
                    ) : (
                      <Checkbox
                        onClick={() => handleClick(item)}
                        value={item.check}
                        name={item.nameBackend}
                      />
                    )}
                  </div>
                  <div className="mx-1"> {t(item.nameBackend)} </div>
                </div>
                <div className="w-25">
                  <p>{t(item.engName)}</p>
                </div>
                <div  className="d-flex w-18">
                  <p>{item.time} ({t("8may")})  </p>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
}

import React from "react";
import "./style.css";
import logo from "../../Assets/logo.png";
import Button from "react-bootstrap/Button";
import Footer from "../../Components/Footer/Footer";
import { useHistory } from "react-router-dom";
import i18next from "i18next";

export default function RegisterPage() {
  const history = useHistory();
  return (
    <div className="reg-container">
      <img src={logo} alt="logo" className="logo-img" />
      <div className="d-flex flex-column  align-items-center w-100">
        <h1 className="text-center mb-4">تسجل لحضور ورشة عمل </h1>
        <h1 className="text-center">Register to attend a workshop </h1>
      </div>
      <div className="btn-wrapper d-flex">
        <Button
          onClick={() => {
            i18next.changeLanguage("ar");
            history.push("/register/ar");
          }}
          variant="success"
          className="reg-btn"
        >
          سجل الان
        </Button>
        <Button
          onClick={() => {
            i18next.changeLanguage("en");
            history.push("/register/en");
          }}
          variant="success"
          className="reg-btn"
        >
          Register Now
        </Button>
      </div>
      <Footer />
    </div>
  );
}

const Data = [
  {
    check: localStorage.getItem("course1_8"),
    nameBackend: "course1_8",
    date: "8may",
    engName: "eng1_8",
    time: "10:00 - 10:25 AM",
    key: 1,
  },
  {
    check: localStorage.getItem("course2_8"),
    nameBackend: "course2_8",
    date: "8may",
    engName: "eng2_8",
    time: "10:30 - 10:55 AM",
    key: 2,
  },
  {
    check: localStorage.getItem("course3_8"),
    nameBackend: "course3_8",
    date: "8may",
    engName: "eng3_8",
    time: "11:00 - 11:25 AM",
    key: 3,
  },
  {
    check: localStorage.getItem("course4_8"),
    nameBackend: "course4_8",
    engName: "eng4_8",
    time: "11:30 - 11:55 AM",
    key: 4,
    date: "8may",
  },
  {
    check: localStorage.getItem("course5_8"),
    nameBackend: "course5_8",
    engName: "eng5_8",
    time: "8:00 - 8:25 PM",
    key: 5,
    date: "9may",
  },
  {
    check: localStorage.getItem("course6_8"),
    nameBackend: "course6_8",
    date: "10may",
    engName: "eng6_8",
    time: "8:00 - 8:25 PM",
    key: 6,
  },
  {
    check: localStorage.getItem("course7_8"),
    nameBackend: "course7_8",
    date: "8may",
    engName: "eng7_8",
    time: "5:00 - 5:25 PM",
    key: 7,
  },
  {
    check: localStorage.getItem("course8_8"),
    nameBackend: "course8_8",
    date: "8may",
    engName: "eng8_8",
    time: "5:30 - 5:55 PM",
    key: 8,
  },
  {
    check: localStorage.getItem("course9_8"),
    nameBackend: "course9_8",
    date: "8may",
    engName: "eng9_8",
    time: "6:00 - 6:25 PM",
    key: 9,
  },
  {
    check: localStorage.getItem("course10_8"),
    nameBackend: "course10_8",
    date: "8may",
    engName: "eng10_8",
    time: "6:30 - 6:55 PM",
    key: 10,
  },
  {
    check: localStorage.getItem("course11_8"),
    date: "8may",
    nameBackend: "course11_8",
    engName: "eng11_8",
    time: "7:00 - 7:25 PM",
    key: 11,
  },
  {
    check: localStorage.getItem("course12_8"),
    date: "8may",
    nameBackend: "course12_8",
    engName: "eng12_8",
    time: "7:30 - 7:55 PM",
    key: 12,
  },

  {
    key: 1,
    check: localStorage.getItem("course1_9"),
    nameBackend: "course1_9",
    date: "9may",
    engName: "eng1_9",
    time: "10:00 - 10:25 AM",
  },
  {
    check: localStorage.getItem("course2_9"),
    nameBackend: "course2_9",
    date: "9may",
    engName: "eng2_9",
    time: "10:30 - 10:55 AM",
    key: 2,
  },
  {
    check: localStorage.getItem("course3_9"),
    nameBackend: "course3_9",
    date: "9may",
    engName: "eng3_9",
    time: "11:00 - 11:25 AM",
    key: 3,
  },
  {
    check: localStorage.getItem("course4_9"),
    nameBackend: "course4_9",
    date: "9may",
    engName: "eng4_9",
    time: "11:30 - 11:55 AM",
    key: 4,
  },
  {
    check: localStorage.getItem("course5_9"),
    nameBackend: "course5_9",
    date: "9may",
    engName: "eng5_9",
    time: "12:00 - 12:25 PM",
    key: 5,
  },
  {
    check: localStorage.getItem("course6_9"),
    nameBackend: "course6_9",
    date: "9may",
    engName: "eng6_9",
    time: "12:30 - 12:55 PM",
    key: 6,
  },
  {
    check: localStorage.getItem("course7_9"),
    nameBackend: "course7_9",
    date: "9may",
    engName: "eng7_9",
    time: "5:00 - 5:25 PM",
    key: 7,
  },
  {
    check: localStorage.getItem("course8_9"),
    nameBackend: "course8_9",
    date: "9may",
    engName: "eng8_9",
    time: "5:30 - 5:55 PM",
    key: 8,
  },
  {
    check: localStorage.getItem("course9_9"),
    nameBackend: "course9_9",
    date: "9may",
    engName: "eng9_9",
    time: "6:00 - 6:25 PM",
    key: 9,
  },
  {
    check: localStorage.getItem("course10_9"),
    nameBackend: "course10_9",
    date: "9may",
    engName: "eng10_9",
    time: "6:30 - 6:55 PM",
    key: 10,
  },
  {
    check: localStorage.getItem("course11_9"),
    nameBackend: "course11_9",
    date: "9may",
    engName: "eng11_9",
    time: "7:00 - 7:25 PM",
    key: 11,
  },
  {
    check: localStorage.getItem("course12_9"),
    nameBackend: "course12_9",
    engName: "eng12_9",
    time: "07:30 - 7:55 PM ",
    key: 12,
    date: "9may",
  },

  {
    key: 1,
    check: localStorage.getItem("course1_10"),
    nameBackend: "course1_10",
    date: "10may",
    engName: "eng1_10",
    time: "10:00 - 10:25 AM",
  },
  {
    key: 2,
    check: localStorage.getItem("course2_10"),
    nameBackend: "course2_10",
    engName: "eng2_10",
    time: "10:30 - 10:55 AM",
    date: "10may",
  },
  {
    key: 3,
    check: localStorage.getItem("course3_10"),
    nameBackend: "course3_10",
    date: "10may",
    engName: "eng3_10",
    time: "11:00 - 11:25 AM",
  },
  {
    key: 4,
    check: localStorage.getItem("course4_10"),
    nameBackend: "course4_10",
    date: "10may",
    engName: "eng4_10",
    time: "11:30 - 11:55 AM",
  },
  {
    key: 5,
    check: localStorage.getItem("course5_10"),
    nameBackend: "course5_10",
    date: "10may",
    engName: "eng5_10",
    time: "12:00 - 12:25 PM",
  },
  {
    key: 6,
    check: localStorage.getItem("course6_10"),
    nameBackend: "course6_10",
    date: "10may",
    engName: "eng6_10",
    time: "12:30 - 12:55 PM",
  },
  {
    key: 7,
    check: localStorage.getItem("course7_10"),
    nameBackend: "course7_10",
    date: "10may",
    engName: "eng7_10",
    time: "5:00 - 5:25 PM",
  },
  {
    key: 8,
    check: localStorage.getItem("course8_10"),
    nameBackend: "course8_10",
    date: "10may",
    engName: "eng8_10",
    time: "5:30 - 5:55 PM",
  },
  {
    key: 9,
    check: localStorage.getItem("course9_10"),
    nameBackend: "course9_10",
    date: "10may",
    engName: "eng9_10",
    time: "6:00 - 6:25 PM",
  },
  {
    key: 10,
    check: localStorage.getItem("course10_10"),
    nameBackend: "course10_10",
    date: "10may",
    engName: "eng10_10",
    time: "6:30 - 6:55 PM",
  },
  {
    key: 11,
    check: localStorage.getItem("course11_10"),
    nameBackend: "course11_10",
    date: "10may",
    engName: "eng11_10",
    time: "7:00 - 7:25 PM",
  },
  {
    key: 12,
    check: localStorage.getItem("course12_10"),
    nameBackend: "course12_10",
    date: "10may",
    engName: "eng12_10",
    time: "07:30 - 7:55 PM ",
  },
    {
      key: 1,
      check:  localStorage.getItem("course1_11"),
      nameBackend: "course1_11",
      date: "11may",
      engName: "eng1_11",
      time: "10:00 - 10:25 AM",
    },
    {
      key: 2,
      check:  localStorage.getItem("course2_11"),
      nameBackend: "course2_11",
      date: "11may",
      engName: "eng2_11",
      time: "10:30 - 10:55 AM",
    },
    {
      key: 3,
      check:  localStorage.getItem("course3_11"),
      nameBackend: "course3_11",
      date: "11may",
      engName: "eng3_11",
      time: "11:00 - 11:25 AM",
    },
    {
      key: 4,
      check:  localStorage.getItem("course4_11"),
      nameBackend: "course4_11",
      date: "11may",
      engName: "eng4_11",
      time: "11:30 - 11:55 AM",
    },
    {
      key: 5,
      check:  localStorage.getItem("course5_11"),
      nameBackend: "course5_11",
      date: "11may",
      engName: "eng5_11",
      time: "12:00 - 12:25 PM",
    },
    {
      key: 6,
      check:  localStorage.getItem("course6_11"),
      nameBackend: "course6_11",
      date: "11may",
      engName: "eng6_11",
      time: "12:30 - 12:55 PM",
    },
    {
      key: 7,
      check:  localStorage.getItem("course7_11"),
      nameBackend: "course7_11",
      date: "11may",
      engName: "eng7_11",
      time: "5:00 - 5:25 PM",
    },
    {
      key: 8,
      check:  localStorage.getItem("course8_11"),
      nameBackend: "course8_11",
      date: "11may",
            engName: "eng8_11",
      time: "5:30 - 5:55 PM",
    },
    {
      key: 9,
      check:  localStorage.getItem("course9_11"),
      nameBackend: "course9_11",
      date: "11may",
      engName: "eng9_11",
      time: "6:00 - 6:25 PM",
    },
    {
      key: 10,
      check:  localStorage.getItem("course10_11"),
      nameBackend: "course10_11",
      date: "11may",
      engName: "eng10_11",
      time: "6:30 - 6:55 PM",
    },
    {
      key: 11,
      check:  localStorage.getItem("course11_11"),
      nameBackend: "course11_11",
      date: "11may",
      engName: "eng11_11",
      time: "7:00 - 7:25 PM",
    },
  ]

export default Data;

import Video from "./Assets/Abstract Background.mp4";
import RegisterPage from "./Screens/RegisterPage/RegisterPage";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Stepthree from "./Screens/Application/Stepthree";
import ConfirmApp from "./Screens/Application/ConfirmApp";
import { Switch } from "react-router-dom";
import { Route } from "react-router-dom";
import ConfirmWorkship from "./Screens/Application/ConfirmWorkship";
import WorkShop from "./Screens/Application/WorkShop";
import NotFound from "./Screens/Application/NotFound";

function App() {
  return (
    <div className="App">
      <video
        autoPlay
        muted
        loop
        className="video"
        src={Video}
        type="video/mp4"
      ></video>

      <Switch>
        <Route key={1} path={"/"} exact={true} component={RegisterPage} />
        <Route
          key={4}
          path={"/register/:lang"}
          exact={true}
          component={Stepthree}
        />

        <Route
          key={5}
          path={"/confirm-workship/:lang"}
          exact={true}
          component={ConfirmWorkship}
        />

        <Route key={6} path={"/confirm"} exact={true} component={ConfirmApp} />
        <Route
          key={7}
          path={"/workshop/:lang"}
          exact={true}
          component={WorkShop}
        />
        <Route key={8} path={""} exact={true} component={NotFound} />
      </Switch>
    </div>
  );
}

export default App;

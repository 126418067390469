import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Checkbox from "../../Components/CheckBox/Checkbox";
import DisabledCheckbox from "../../Components/CheckBoxRadio/Checkbox";
import Loading from "../../Components/Loading/Loading";
import "./style.css"

 

export default function Tabthree({setCheckThree}) {

  const { t } = useTranslation();

  const handleClick = useCallback((item) => {
    setData((prev) => {
      const res = prev.map((_item) => {
        if (item.key === _item.key)
          return {
            ..._item,
            check: !_item.check,
          };
        else return _item;
      });
      console.log("res", res);
      return res;
    });
  }, []);
  const [data, setData] = useState([
    {
      key: 1,
      check:  localStorage.getItem("course1_10"),
      nameBackend: "course1_10",
      name: t("course1_10"),
      engName: "eng1_10",
      time: "10:00 - 10:25 AM",
    },
    {
      key: 2,
      check:  localStorage.getItem("course2_10"),
      nameBackend: "course2_10",
      name: t("course2_10"),
      engName: "eng2_10",
      time: "10:30 - 10:55 AM",
    },
    {
      key: 3,
      check:  localStorage.getItem("course3_10"),
      nameBackend: "course3_10",
      name: t("course3_10"),
      engName: "eng3_10",
      time: "11:00 - 11:25 AM",
    },
    {
      key: 4,
      check:  localStorage.getItem("course4_10"),
      nameBackend: "course4_10",
      name: t("course4_10"),
      engName: "eng4_10",
      time: "11:30 - 11:55 AM",
    },
    {
      key: 5,
      check:  localStorage.getItem("course5_10"),
      nameBackend: "course5_10",
      name: t("course5_10"),
      engName: "eng5_10",
      time: "12:00 - 12:25 PM",
    },
    {
      key: 6,
      check:  localStorage.getItem("course6_10"),
      nameBackend: "course6_10",
      name: t("course6_10"),
      engName: "eng6_10",
      time: "12:30 - 12:55 PM",
    },
    {
      key: 7,
      check:  localStorage.getItem("course7_10"),
      nameBackend: "course7_10",
      name: t("course7_10"),
      engName: "eng7_10",
      time: "5:00 - 5:25 PM",
    },
    {
      key: 8,
      check:  localStorage.getItem("course8_10"),
      nameBackend: "course8_10",
      name: t("course8_10"),
      engName: "eng8_10",
      time: "5:30 - 5:55 PM",
    },
    {
      key:9,
      check:  localStorage.getItem("course9_10"),
      nameBackend: "course9_10",
      name: t("course9_10"),
      engName: "eng9_10",
      time: "6:00 - 6:25 PM",
    },
    {
      key: 10,
      check:  localStorage.getItem("course10_10"),
      nameBackend: "course10_10",
      name: t("course10_10"),
      engName: "eng10_10",
      time: "6:30 - 6:55 PM",
    },
    {
      key: 11,
      check:  localStorage.getItem("course11_10"),
      nameBackend: "course11_10",
      name: t("course11_10"),
      engName: "eng11_10",
      time: "7:00 - 7:25 PM",
    },
    {
      key: 12,
      check:  localStorage.getItem("course12_10"),
      nameBackend: "course12_10",
      name: t("course12_10"),
      engName: "eng12_10",
      time:"07:30 - 7:55 PM "
    },
    {
      check:  localStorage.getItem("course6_8"),
      nameBackend: "course6_8",
      name: t("course6_8"),
      engName: "eng6_8",
      time: "8:00 - 8:25 PM",
      key: 6,
    },
  ])

  
  useEffect(() => {
    if (data.some((item) => item.check == true)) setCheckThree(true);
    // else setCheckThree(false);
  }, [data]);

  const [req, setReq] = useState([]);
  const [loading, setLoading] = useState(true);


  useEffect(async () => {
    const res = await axios.get(
      `https://dashboard.moe-workshops.its.ae/api/order`
    );
    const index = res.data;
    setReq(res.data);

    setLoading(false);
  }, []);

  return (

    <>
    {loading ? (
      <Loading />
    ) : (
    <div className="bg-color">
      {data.map((item) => {
        return (
          <div className=" d-flex justify-content-between mb-3">
            <div className="w-50  d-flex">
            <div>
                    {req[item.nameBackend] >= 20 ? (
                      <DisabledCheckbox />
                    ) : (

                <Checkbox
                  onClick={() => handleClick(item)}
                  value={item.check}
                  name={item.nameBackend}
                />
                )}
                </div>
              <div className="mx-1"> {t(item.nameBackend)} </div>
            </div>
            <div className="w-25">
              <p>{t(item.engName)}</p>
            </div>
            <div className="w-18">
              <p>{item.time} ({t("10may")})</p>
            </div>
          </div>
        );
      })}
    </div>

)}
</>
  );
}

import { Button } from "react-bootstrap";
import Layout from "../Layout/Layout";
import { useHistory } from "react-router-dom";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

export default function NotFound() {
  const history = useHistory();

  const { t } = useTranslation();

  return (
    <Layout>
      <div className="d-flex   flex-column  align-items-center justify-content-between not-found">
        <h1>404 Page Not Found</h1>

        <div className="mt-5">
          <Button
            onClick={() => {
              i18next.changeLanguage("ar");
              history.push("/");
            }}
            variant="success"
            className="reg-btn"
          >
            {t("home")}
          </Button>
        </div>
      </div>
    </Layout>
  );
}

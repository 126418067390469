import React from "react";
import { ReactComponent as LogoSvg } from '../../Assets/logobigbang.svg';
import "./style.css"

export default function Footer() {
  return (
    <div className="container d-flex justify-content-center footer-container my-4">

        {/* <LogoSvg  style={{height:"30px"}} /> */}
    </div>
  );
}

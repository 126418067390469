import React, { useEffect } from "react";
import Layout from "../Layout/Layout";
import { useTranslation } from "react-i18next";
import ConfirmAnimation from "../../Components/ConfirmAnimation/ConfirmAnimation";
import i18next from "i18next";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";

export default function ConfirmApp() {
  console.log("nes");

  useEffect(() => {
    window.localStorage.clear();
  }, []);

  const history = useHistory();

  const { t } = useTranslation();
  return (
    <Layout>
      <div
        className="content-text d-flex flex-column align-items-center"
        style={{ paddingTop: "100px" }}
      >
        <div>
          <ConfirmAnimation />
        </div>

        <p className="confirm-text">{t("confirm_app")} <br/>
        {t("confirm_app_2")}
        </p>

        <div className="mt-5">
          <Button
            onClick={() => {
              i18next.changeLanguage("ar");
              history.push("/");
            }}
            variant="success"
            className="reg-btn"
          >
            {t("home")}
          </Button>
        </div>
      </div>
    </Layout>
  );
}
